import './App.css';
import React from 'react';
import { useEffect } from 'react';

const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const numbers = '0123456789';
const symbols = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
const all = letters + numbers + symbols;

function genPassword(length) {
  let password = '';
  for(let i = 0; i < length; i++) {
    password += all[Math.floor(Math.random() * all.length)];
  }
  return password;
}



function App() {

  useEffect(() => {
    document.querySelector('#password').onmouseover = event => {
      let i = 0;
      const interval = setInterval(() => {
        event.target.innerText = event.target.innerText.split('')
          .map((letter, index) => {
            if(index < i) {
              return event.target.dataset.value[index];
            }
            return letters[Math.floor(Math.random() * letters.length)]
          })
          .join('');
        if(i >= event.target.dataset.value.length) {
          clearInterval(interval);
        }
        i++;
      }, 40);
    }
    }, []);
  useEffect(() => {
    document.addEventListener('click', event => {
      if(event.target.tagName === 'BUTTON') {
        const passwordLength = document.querySelector('.slider').value;
        const password = genPassword(passwordLength);
        document.querySelector('#password').dataset.value = password;
        document.querySelector('#password').innerText = password;
      }
  })
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <div className="App-body">
        <div id="main-body">
          <h1 id="password" data-value="PASSWORD GENERATOR">PASSWORD GENERATOR</h1>
        </div>
        <div id="password-keys">
          <form>
            <div class="slidecontainer">
              <input type="range" className="slider" min='8' max='24' defaultValue='16'/>
            </div>
            <br/>
            <button type="button" className='button-68' role="button">Generate Password</button>
          </form>
        </div>
      </div>
      </header>
    </div>
  );
}

export default App;
